<template>
  <div>
    <div class="table-box">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="TaskName" label="任务名称"></el-table-column>
        <el-table-column
          prop="CreateTime"
          label="创建时间"
        ></el-table-column>
        <el-table-column label="进度" >
          <template #default="scope">
            <div class="fail-wrap">
              <p>{{ scope.row.Process }}%</p>
              <el-slider
                v-model="scope.row.Process"
                :min="0"
                :max="100"
                disabled
                class="slider"
              ></el-slider>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Status" label="状态">
          <template #default="scope">
            <div class="fail-wrap">
              <div>{{ changeStatus(scope.row.Status) }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="220">
          <template #default="scope">
            <el-button type="primary" v-if="scope.row.Status==0" @click="cancelAction(scope.row)">取消</el-button>
            <el-button type="primary" v-if="scope.row.Status==2 || scope.row.Status==11 || scope.row.Status==4" @click="checkDetail(scope.row)" >导出明细</el-button>
            <el-button type="primary" v-if="scope.row.Status==1" @click="stopAction(scope.row)">终止</el-button>
          </template>
        </el-table-column>
      </el-table>
      <TableFoot
        :pagination="pageObj"
        @changePage="changePage"
        @changeSize="changeSize"
      />
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import { ElMessageBox, ElMessage } from "element-plus";
import { useStore } from "vuex";
import {GroupModifyTaskList,GroupModifyExportTaskInfo,GroupModifyCancel,GroupModifyStop} from "@/helper/group.js";
import tools from "@/assets/js/tools";

export default {
  name: "GroupNameInfo",
  components: {
    TableFoot,
  },

  setup() {
    const router = useRouter();
    const tableData = ref([]);
    const store = useStore()
    const route = useRoute()

    onMounted(() => {
      searchAction();
    });


    /**********************搜索***************************/
    //转换状态
    function changeStatus(status) {
      if (status == 0) {
        return "未开始";
      } else if (status == 1) {
        return "进行中";
      } else if (status == 2) {
        return "全部完成";
      } else if (status == 3) {
        return "已取消";
      } else if (status == 11) {
        return "部分成功";
      } else if(status == 4){
        return '已终止'
      }
    }

    //执行搜索
    function searchAction() {
        let param={
          PageNum:pageObj.PageIndex,
          PageSize:pageObj.PageSize,
          Type:300002
        }
        GroupModifyTaskList(param).then(res=>{
          pageObj.TotalRow=res.TotalCount
          tableData.value=res.List
        })
    }

    function checkDetail(row){
      GroupModifyExportTaskInfo({TaskID:row.TaskID}).then(res=>{
        window.location.href = res.File;
          ElMessage({
            type: 'success',
            message: '导出成功!',
          });
      })
    }

    //取消
    function cancelAction(row){
       ElMessageBox.confirm("此操作将取消改任务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          GroupModifyCancel({TaskID:row.TaskID}).then(res=>{
             ElMessage({
              type: "success",
              message: "取消成功!",
            });
            searchAction()
          })
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
        });
      
    }

    //终止
    function stopAction(row){
      ElMessageBox.confirm("此操作将终止此任务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          GroupModifyStop({TaskID:row.TaskID}).then(res=>{
             ElMessage({
              type: "success",
              message: "终止成功!",
            });
            searchAction()
          })
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
        });

    }

    /****************TableFoot*******************/
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      searchAction();
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      searchAction();
    }
    function onSureGroup(){
      searchAction();
    }
    return {
      onSureGroup,
      tableData,
      searchAction,
      changePage,
      changeSize,
      pageObj,
      changeStatus,
      checkDetail,
      cancelAction,
      stopAction
    };
  },
};
</script>
<style lang="scss" scoped>
.fail-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    width: 34px;
  }
  span {
    color: $color-common;
  }
}

.slider {
  width: 60px;

  :deep() .el-slider__bar {
    background-color: $color-common;
  }

  :deep() .el-slider__button {
    width: 10px;
    height: 10px;
    // border:none;
  }
}
</style>